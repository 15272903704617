import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "section", "linkIcon"]

    check(event) {
        const code = this.inputTarget.value
        const documentId = document.getElementById('id').value
        if (code.trim() !== '') {
            fetch(`/check_discount_code?code=${code}&document_id=${documentId}`)
            .then(response => response.json())
            .then(data => {
                const pageElement = this.element.closest('.page')
                let alertElement = pageElement.querySelector('.error')
                const paymentContainer = pageElement.querySelector('.payment-container')

                // If the alert element doesn't exist, create it
                if (!alertElement) {
                    alertElement = document.createElement('div')
                }

                // Update the alert element with the new message
                alertElement.innerHTML = data.message

                // Insert the alert element before the payment container
                if (paymentContainer) {
                    pageElement.insertBefore(alertElement, paymentContainer)
                } else {
                    // If the payment container doesn't exist, append the alert element to the page element
                    pageElement.appendChild(alertElement)
                }
            })
        }
    }

    toggleSection() {
        this.sectionTarget.classList.toggle("hidden");
        this.updateLinkTextAndIcon();
    }

    updateLinkTextAndIcon() {
        const isSectionVisible = !this.sectionTarget.classList.contains("hidden");
        const iconPath = isSectionVisible ? 'chevron-up.svg' : 'chevron-down.svg';
        this.linkIconTarget.src = `/assets/${iconPath}`;
        this.element.querySelector('a').prepend(this.linkIconTarget);
    }
}
