import { Controller } from "stimulus"
import QRCode from 'qrcode';
import sha256 from 'js-sha256';

export default class extends Controller {
    connect() {
        this.qr_data = String(this.data.get("qrData"))
        this.generateAndRenderQRCode()
    }

    generateAndRenderQRCode() {
        this.element.innerHTML = ''
        QRCode.toDataURL(this.qr_data, {
            errorCorrectionLevel: 'L',
            type: 'image/png',
            scale: 4,
            margin: 1,
        }, (err, url) => {
            if (err) throw err
            const img = document.createElement('img')
            img.src = url
            img.style.width = '170px' // Adjust the width as needed
            img.style.height = '170px' // Adjust the height as needed
            img.style.display = 'block' // To use margin auto for centering
            img.style.margin = 'auto' // Center the image
            this.element.appendChild(img)
        })
    }
}